/* ------------------------------------------------------------------- */
/* Red #e32b2b
---------------------------------------------------------------------- */

a,
.header-widget li i,
.featured-service i,
.stage i,
.stage span,
#breadcrumbs ul li a:hover,
.headline span.color,
.menu > li a:hover,
a.button.border,
.list-1 li:before,
.info-banner-fw a:hover {
	color: #e32b2b;
}

body,
.menu > li a:hover,
.dropdown ul,
a.button.border,
a.button.border:hover,
.custom-caption {
	border-color: #e32b2b;
}


.zeus .tp-title-wrap,
a.button.border::before {
    background: #e32b2b;
}

#logo img,
li.dropdown ul li a:hover,
.menu-responsive,
a.featured-service:hover,
#backtotop a:hover,
.sidebar-textbox.color,
input[type="button"],
input[type="submit"],
a.button,
.info-banner-fw,
.services-list li a.active,
.services-list li a:hover {
	background-color: #e32b2b;
}